var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v("Click one tile including a cow's head.")]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
        ]),
        _vm._m(1),
        _vm._m(2)
      ])
    ]),
    _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v("Click one tile including a cow's tail.")]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
        ]),
        _vm._m(3),
        _vm._m(4)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v("This task is labeling a cow's head and tail.")]),
      _c("h1", [_vm._v("First, click one tile including a cow's head.")]),
      _c("h1", [_vm._v("Next, click one tile including a cow's tail")]),
      _c("h1", [_vm._v("and continue the same task using another image.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "container" } }, [
      _c("button", { attrs: { id: "item00" } }),
      _c("button", { attrs: { id: "item01" } }),
      _c("button", { attrs: { id: "item02" } }),
      _c("button", { attrs: { id: "item10" } }),
      _c("button", { attrs: { id: "item11" } }),
      _c("button", { attrs: { id: "item12" } }),
      _c("button", { attrs: { id: "item20" } }),
      _c("button", { attrs: { id: "item21" } }),
      _c("button", { attrs: { id: "item22" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow1" }, [
      _c("h2", [_vm._v("Click")]),
      _vm._v(" ⬅ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "container" } }, [
      _c("button", { attrs: { id: "item00" } }),
      _c("button", { attrs: { id: "item01" } }),
      _c("button", { attrs: { id: "item02" } }),
      _c("button", { attrs: { id: "item10" } }),
      _c("button", { attrs: { id: "item11" } }),
      _c("button", { attrs: { id: "item12" } }),
      _c("button", { attrs: { id: "item20" } }),
      _c("button", { attrs: { id: "item21" } }),
      _c("button", { attrs: { id: "item22" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow2" }, [
      _c("h2", [_vm._v("Click")]),
      _vm._v(" ➡ ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }