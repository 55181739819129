var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q1.")]),
      _vm._v(" What is the name of this character?"),
      _c("br"),
      _c("img", {
        attrs: {
          src:
            "https://pbs.twimg.com/profile_images/561277979855056896/4yRcS2Zo_400x400.png",
          width: "100"
        }
      }),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true }
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.characterName,
            expression: "characterName"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.characterName },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.characterName = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q2.")]),
      _vm._v(" Select all Japanese names that you know."),
      _c("br"),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.celebrities,
                expression: "celebrities"
              }
            ],
            attrs: { type: "checkbox", value: "Hayao Miyazaki" },
            domProps: {
              checked: Array.isArray(_vm.celebrities)
                ? _vm._i(_vm.celebrities, "Hayao Miyazaki") > -1
                : _vm.celebrities
            },
            on: {
              change: function($event) {
                var $$a = _vm.celebrities,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "Hayao Miyazaki",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.celebrities = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.celebrities = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.celebrities = $$c
                }
              }
            }
          }),
          _vm._v("Hayao Miyazaki")
        ]),
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.celebrities,
                expression: "celebrities"
              }
            ],
            attrs: { type: "checkbox", value: "Pikotaro" },
            domProps: {
              checked: Array.isArray(_vm.celebrities)
                ? _vm._i(_vm.celebrities, "Pikotaro") > -1
                : _vm.celebrities
            },
            on: {
              change: function($event) {
                var $$a = _vm.celebrities,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "Pikotaro",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.celebrities = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.celebrities = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.celebrities = $$c
                }
              }
            }
          }),
          _vm._v("Pikotaro")
        ]),
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.celebrities,
                expression: "celebrities"
              }
            ],
            attrs: { type: "checkbox", value: "Yoko Ono" },
            domProps: {
              checked: Array.isArray(_vm.celebrities)
                ? _vm._i(_vm.celebrities, "Yoko Ono") > -1
                : _vm.celebrities
            },
            on: {
              change: function($event) {
                var $$a = _vm.celebrities,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "Yoko Ono",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.celebrities = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.celebrities = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.celebrities = $$c
                }
              }
            }
          }),
          _vm._v("Yoko Ono")
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.celebrities,
                expression: "celebrities"
              }
            ],
            attrs: { type: "checkbox", value: "Keisuke Honda" },
            domProps: {
              checked: Array.isArray(_vm.celebrities)
                ? _vm._i(_vm.celebrities, "Keisuke Honda") > -1
                : _vm.celebrities
            },
            on: {
              change: function($event) {
                var $$a = _vm.celebrities,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "Keisuke Honda",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.celebrities = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.celebrities = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.celebrities = $$c
                }
              }
            }
          }),
          _vm._v("Keisuke Honda")
        ]),
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.celebrities,
                expression: "celebrities"
              }
            ],
            attrs: { type: "checkbox", value: "None of them" },
            domProps: {
              checked: Array.isArray(_vm.celebrities)
                ? _vm._i(_vm.celebrities, "None of them") > -1
                : _vm.celebrities
            },
            on: {
              change: function($event) {
                var $$a = _vm.celebrities,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "None of them",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.celebrities = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.celebrities = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.celebrities = $$c
                }
              }
            }
          }),
          _vm._v("None of them")
        ])
      ])
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q3.")]),
      _vm._v(" Are you an American citizen?"),
      _c("br"),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isAmerican,
                expression: "isAmerican"
              }
            ],
            attrs: { type: "radio", value: "Yes" },
            domProps: { checked: _vm._q(_vm.isAmerican, "Yes") },
            on: {
              change: function($event) {
                _vm.isAmerican = "Yes"
              }
            }
          }),
          _vm._v("Yes")
        ]),
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            directives: [
              {
                name: "nano",
                rawName: "v-nano.required",
                modifiers: { required: true }
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isAmerican,
                expression: "isAmerican"
              }
            ],
            attrs: { type: "radio", value: "No" },
            domProps: { checked: _vm._q(_vm.isAmerican, "No") },
            on: {
              change: function($event) {
                _vm.isAmerican = "No"
              }
            }
          }),
          _vm._v("No")
        ])
      ])
    ]),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit },
          on: {
            click: function($event) {
              _vm.canSubmit ? _vm.submit() : false
            }
          }
        },
        [_vm._v("NEXT")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Pre-survey.")]),
      _vm._v(" Please answer the following questions first. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }