var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
      ]),
      _c("div", { attrs: { id: "container" } }, [
        _c("button", {
          attrs: { id: "item00" },
          on: {
            click: function($event) {
              return _vm.submitChoice("00")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item01" },
          on: {
            click: function($event) {
              return _vm.submitChoice("01")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item02" },
          on: {
            click: function($event) {
              return _vm.submitChoice("02")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item10" },
          on: {
            click: function($event) {
              return _vm.submitChoice("10")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item11" },
          on: {
            click: function($event) {
              return _vm.submitChoice("11")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item12" },
          on: {
            click: function($event) {
              return _vm.submitChoice("12")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item20" },
          on: {
            click: function($event) {
              return _vm.submitChoice("20")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item21" },
          on: {
            click: function($event) {
              return _vm.submitChoice("21")
            }
          }
        }),
        _c("button", {
          attrs: { id: "item22" },
          on: {
            click: function($event) {
              return _vm.submitChoice("22")
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }