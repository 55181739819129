var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v('This is a cow\'s "Sitting" case.')]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _vm._m(1),
        _c(
          "button",
          { staticClass: "button Sitting", attrs: { disabled: true } },
          [_vm._v("Sitting")]
        ),
        _c(
          "button",
          { staticClass: "button Standing", attrs: { disabled: true } },
          [_vm._v("Standing")]
        ),
        _c("div", { staticClass: "check-item" }, [
          _c("input", { attrs: { disabled: true, type: "checkbox" } }),
          _vm._v("Others")
        ])
      ])
    ]),
    _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v('This is a cow\'s "Standing" case.')]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", { attrs: { src: _vm.nano.props.img_url1 } })
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "button",
          { staticClass: "button Sitting", attrs: { disabled: true } },
          [_vm._v("Sitting")]
        ),
        _vm._m(2),
        _c(
          "button",
          { staticClass: "button Standing", attrs: { disabled: true } },
          [_vm._v("Standing")]
        ),
        _c("div", { staticClass: "check-item" }, [
          _c("input", { attrs: { disabled: true, type: "checkbox" } }),
          _vm._v("Others")
        ]),
        _c("p")
      ])
    ]),
    _c("div", { staticClass: "card" }, [
      _c("h1", [_vm._v('This is an "Others" case.')]),
      _c("div", { staticClass: "flex" }, [
        _c("div", { staticClass: "img-wrapper" }, [
          _c("img", { attrs: { src: _vm.nano.props.img_url2 } })
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _c(
          "button",
          { staticClass: "button Sitting", attrs: { disabled: true } },
          [_vm._v("Sitting")]
        ),
        _c(
          "button",
          { staticClass: "button Standing", attrs: { disabled: true } },
          [_vm._v("Standing")]
        ),
        _vm._m(3),
        _c("div", { staticClass: "check-item" }, [
          _c("input", {
            attrs: { disabled: true, type: "checkbox", checked: "" }
          }),
          _vm._v("Others")
        ])
      ]),
      _c("div", { staticClass: "flex" }, [
        _vm._m(4),
        _c("ul", [
          _vm._v(
            " Chose reasons, please. (You can chose one reason or many.) "
          ),
          _c("div", { staticClass: "check-item-others" }, [
            _c("input", {
              attrs: { type: "checkbox", disabled: true, checked: "" }
            }),
            _vm._v(" too close to a cow ")
          ]),
          _c("div", { staticClass: "check-item-others" }, [
            _c("input", { attrs: { type: "checkbox", disabled: true } }),
            _vm._v(" bad camera angle ")
          ]),
          _c("div", { staticClass: "check-item-others" }, [
            _c("input", { attrs: { type: "checkbox", disabled: true } }),
            _vm._v(" image is not clear ")
          ])
        ])
      ]),
      _c("div", { staticClass: "next-btn-wrapper" }, [
        _vm._m(5),
        _c("button", { attrs: { disabled: true } }, [_vm._v("NEXT")])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("h1", [
        _vm._v('Classify cow\'s postures, "Sitting" or "Standing", please.')
      ]),
      _c("h1", [
        _vm._v(
          'Here are three samples. These shows three different cases, "Sitting", "Standing", and "Others".'
        )
      ]),
      _c("h1", [
        _vm._v("There are 200 questions in total. It takes 5 - 10 minutes.")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("h2", [_vm._v("click")]),
      _vm._v(" ➡ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("h2", [_vm._v("click")]),
      _vm._v(" ➡ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("h2", [_vm._v("1.check")]),
      _vm._v(" ➡ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("h2", [_vm._v("2.check")]),
      _vm._v(" ➡ ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "arrow" }, [
      _c("h2", [_vm._v("3.click")]),
      _vm._v(" ➡ ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }