var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
      ])
    ]),
    _c("div", { staticClass: "flex" }, [
      _c(
        "button",
        {
          staticClass: "button Sitting",
          on: {
            click: function($event) {
              return _vm.submitChoice("Sitting")
            }
          }
        },
        [_vm._v("Sitting")]
      ),
      _c(
        "button",
        {
          staticClass: "button Standing",
          on: {
            click: function($event) {
              return _vm.submitChoice("Standing")
            }
          }
        },
        [_vm._v("Standing")]
      ),
      _c("div", { staticClass: "check-item" }, [
        _c("input", {
          attrs: { type: "checkbox", value: "irregular" },
          on: {
            click: function($event) {
              _vm.showModal = !_vm.showModal
            }
          }
        }),
        _vm._v("Others")
      ])
    ]),
    _vm.showModal
      ? _c("div", { staticClass: "modal" }, [
          _c("div", { staticClass: "flex" }, [
            _c("ul", [
              _vm._v(
                " Chose reasons, please. (You can chose one reason or many.) "
              ),
              _c("div", { staticClass: "check-item-others" }, [
                _c("input", {
                  directives: [
                    {
                      name: "nano",
                      rawName: "v-nano.required",
                      modifiers: { required: true }
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.irregularCase,
                      expression: "irregularCase"
                    }
                  ],
                  attrs: { type: "checkbox", value: "too close" },
                  domProps: {
                    checked: Array.isArray(_vm.irregularCase)
                      ? _vm._i(_vm.irregularCase, "too close") > -1
                      : _vm.irregularCase
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.irregularCase,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "too close",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.irregularCase = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.irregularCase = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.irregularCase = $$c
                      }
                    }
                  }
                }),
                _vm._v(" too close to a cow ")
              ]),
              _c("div", { staticClass: "check-item-others" }, [
                _c("input", {
                  directives: [
                    {
                      name: "nano",
                      rawName: "v-nano.required",
                      modifiers: { required: true }
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.irregularCase,
                      expression: "irregularCase"
                    }
                  ],
                  attrs: { type: "checkbox", value: "bad angle" },
                  domProps: {
                    checked: Array.isArray(_vm.irregularCase)
                      ? _vm._i(_vm.irregularCase, "bad angle") > -1
                      : _vm.irregularCase
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.irregularCase,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "bad angle",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.irregularCase = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.irregularCase = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.irregularCase = $$c
                      }
                    }
                  }
                }),
                _vm._v(" bad camera angle ")
              ]),
              _c("div", { staticClass: "check-item-others" }, [
                _c("input", {
                  directives: [
                    {
                      name: "nano",
                      rawName: "v-nano.required",
                      modifiers: { required: true }
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.irregularCase,
                      expression: "irregularCase"
                    }
                  ],
                  attrs: { type: "checkbox", value: "unclear image" },
                  domProps: {
                    checked: Array.isArray(_vm.irregularCase)
                      ? _vm._i(_vm.irregularCase, "unclear image") > -1
                      : _vm.irregularCase
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.irregularCase,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "unclear image",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.irregularCase = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.irregularCase = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.irregularCase = $$c
                      }
                    }
                  }
                }),
                _vm._v(" image is not clear ")
              ])
            ])
          ]),
          _c("div", { staticClass: "next-btn-wrapper" }, [
            _c(
              "button",
              {
                attrs: { disabled: !_vm.canSubmit },
                on: {
                  click: function($event) {
                    _vm.canSubmit ? _vm.submit() : false
                  }
                }
              },
              [_vm._v("NEXT")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }