var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h3", [_vm._v("Your task:")]),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("[Q1] "),
        _c("b", [_vm._v("Click")]),
        _vm._v(" on "),
        _c("u", [_vm._v("the base of the cow's tail")]),
        _vm._v("."),
        _c("br"),
        _vm._v(" [Q2] "),
        _c("b", [_vm._v("Click")]),
        _vm._v(" on the image so that the arrow points in "),
        _c("u", [_vm._v("the direction of the spine")]),
        _vm._v(".")
      ]),
      _c("p", { staticClass: "indent-1" }, [
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* This task requires "),
          _c("b", [_vm._v("Adult Content Qualification")]),
          _vm._v(
            " because it includes images with cows giving birth, which may be sensitive."
          )
        ])
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _c("b", [_vm._v("[Q1]")]),
        _c("br"),
        _vm._v("Select "),
        _c("b", [_vm._v("the base of the cow's tail")]),
        _vm._v(" in the image by "),
        _c("b", [_vm._v("clicking")]),
        _vm._v(" on it."),
        _c("br"),
        _vm._v(
          " When you select the base of the tail, a red circle will appear in the area."
        ),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* If you want to start over, click the "),
          _c("b", [_vm._v('"RESET" button')]),
          _vm._v(" at the bottom left of the screen.")
        ]),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(
            "* If the base of the tail is not visible, predict its position from the image and click outside the image."
          )
        ]),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(
            "* If there is more than one cow in the picture, select the base of the tail of the one cow in the "
          ),
          _c("u", [_vm._v("center")]),
          _vm._v(" of the image.")
        ])
      ]),
      _c("img", {
        staticClass: "icon_1",
        attrs: {
          src: "/static/sample_images_hip_direction/hip_direction_icon_q1.png"
        }
      }),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("If the image is not clear, press the "),
        _c("b", [_vm._v('"UNCLEAR" button')]),
        _vm._v(" at the bottom of the screen and proceed to the next image.")
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _c("b", [_vm._v("[Q2]")]),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("Click")]),
        _vm._v(" on the image so that the arrow points in "),
        _c("u", [_vm._v("the direction of the spine")]),
        _vm._v("."),
        _c("br"),
        _vm._v(
          " The direction of the arrow will change to the direction you clicked."
        ),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* You can redo clicking as many times as you like.")
        ])
      ]),
      _c("img", {
        staticClass: "icon_1",
        attrs: {
          src: "/static/sample_images_hip_direction/hip_direction_icon_q2.png"
        }
      }),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("Here, "),
        _c("b", [_vm._v("the spine")]),
        _vm._v(' is defined as "the line connecting '),
        _c("u", [_vm._v("the base of the tail")]),
        _vm._v(" and "),
        _c("u", [_vm._v("the base of the neck")]),
        _vm._v(' of a cow".')
      ]),
      _c("img", {
        staticClass: "icon_2",
        attrs: { src: "/static/sample_images_hip_direction/spine_icon.png" }
      }),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("After answering [Q1] and [Q2], press the "),
        _c("b", [_vm._v('"NEXT" button')]),
        _vm._v(" at the bottom of the screen or press the "),
        _c("b", [_vm._v('"Enter" key')]),
        _vm._v(" to proceed to the next image.")
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("Below are some examples of images and the correct answer."),
        _c("br"),
        _c(
          "span",
          { staticStyle: { "font-size": "16px", "padding-left": "1em" } },
          [_vm._v("(left): Original image, (right): The correct answer.")]
        )
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_1.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_1_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_2.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_2_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_3.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_3_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_4.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_4_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_5.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_5_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_6.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_6_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_7.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_7_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_8.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_8_anno.png"
            }
          })
        ])
      ]),
      _c("p", { staticClass: "indent-1" }, [
        _c(
          "span",
          { staticStyle: { "font-size": "16px", "padding-left": "1em" } },
          [
            _vm._v(
              "If the base of the tail is not visible, predict its position from the image and click outside the image."
            )
          ]
        )
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_3.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_3_arrow.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_1.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_1_arrow.png"
            }
          })
        ])
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("Below is the example of a "),
        _c("b", [_vm._v('"Can\'t tell"')]),
        _vm._v(" image.")
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_direction/direction_cant_tell.png"
            }
          })
        ])
      ]),
      _c("br"),
      _c("h3", [_vm._v("Notes:")]),
      _c("ul", [
        _c("li", [
          _vm._v("You will need to send answers "),
          _c("b", [_vm._v("10 times")]),
          _vm._v(" to finish microtask. ")
        ])
      ]),
      _c("div", [
        _c("i", [
          _vm._v(
            "Your answers of this task are used for the research of improving animal farming. Thank you so much for your cooperation."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }