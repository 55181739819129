var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto my-6 pa-6", attrs: { "max-width": "1000" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { color: "grey lighten-4" } },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "300",
                          src: _vm.nano.props.img_url0,
                          contain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "placeholder",
                            fn: function() {
                              return [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "fill-height ma-0",
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2", align: "center", justify: "center" } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("mdi-arrow-left-right-bold")
                  ])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { color: "grey lighten-4" } },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "300",
                          src: _vm.nano.props.img_url1,
                          contain: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "placeholder",
                            fn: function() {
                              return [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "fill-height ma-0",
                                    attrs: {
                                      align: "center",
                                      justify: "center"
                                    }
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        color: "grey lighten-1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "d-flex", attrs: { justify: "center" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-3",
                  attrs: { "x-large": "", dark: "", color: "green darken-4" },
                  on: {
                    click: function($event) {
                      _vm.nano.ans.choice = "Same"
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("Same")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-3",
                  attrs: { "x-large": "", dark: "", color: "green darken-1" },
                  on: {
                    click: function($event) {
                      _vm.nano.ans.choice = "Maybe Same"
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("Maybe Same")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-3",
                  attrs: { "x-large": "", dark: "", color: "red darken-1" },
                  on: {
                    click: function($event) {
                      _vm.nano.ans.choice = "Maybe Not Same"
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("Maybe Not Same")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-3",
                  attrs: { "x-large": "", dark: "", color: "red darken-4" },
                  on: {
                    click: function($event) {
                      _vm.nano.ans.choice = "Not Same"
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("Not Same")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }