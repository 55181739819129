var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "indent-1" }, [
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* This task requires "),
          _c("b", [_vm._v("Adult Content Qualification")]),
          _vm._v(
            " because it includes images with cows giving birth, which may be sensitive."
          )
        ])
      ]),
      _c("h3", [_vm._v("Your task:")]),
      _c("p", { staticClass: "indent-1" }, [
        _c("b", [_vm._v("[Q1] Click on the cow's part between tail and hip")]),
        _vm._v("."),
        _c("br"),
        _c("b", [_vm._v("[Q2] Click on the cow's part between head and neck")]),
        _vm._v("."),
        _c("br")
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _c("b", [_vm._v("[Q1]")]),
        _c("br"),
        _vm._v("Click on "),
        _c("b", [_vm._v("the cow's hip")]),
        _vm._v(" in the image."),
        _c("br"),
        _vm._v(
          " When you click on the image, a red circle will appear in the area."
        ),
        _c("br"),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* If you want to start over, click the "),
          _c("b", [_vm._v('"RESET" button')]),
          _vm._v(" at the bottom left of the screen.")
        ]),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(
            "* If there is more than one cow in the picture, select the base of the tail of the one cow in the "
          ),
          _c("u", [_vm._v("center")]),
          _vm._v(" of the image.")
        ])
      ]),
      _c("img", {
        staticClass: "icon_1",
        attrs: { src: "/static/kubota/2023-01-11 15.58.32.png" }
      }),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v(
          "If the image cannot be annotated because of the image problem, press the "
        ),
        _c("b", [_vm._v('"Image Problem" button')]),
        _vm._v(" at the bottom of the screen and proceed to the next image.")
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _c("b", [_vm._v("[Q2]")]),
        _c("br"),
        _vm._v("Click on "),
        _c("b", [_vm._v("the cow's head")]),
        _vm._v(" in the image."),
        _c("br"),
        _vm._v(
          " When you click on the image, a green circle will appear in the area."
        ),
        _c("br"),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("* If you want to start over, click the "),
          _c("b", [_vm._v('"RESET" button')]),
          _vm._v(" at the bottom left of the screen.")
        ]),
        _c("br"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(
            "* If there is more than one cow in the picture, select the base of the tail of the one cow in the "
          ),
          _c("u", [_vm._v("center")]),
          _vm._v(" of the image.")
        ])
      ]),
      _c("img", {
        staticClass: "icon_1",
        attrs: { src: "/static/kubota/2023-01-11 16.46.30.png" }
      }),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v(
          "If the image cannot be annotated because of the image problem, press the "
        ),
        _c("b", [_vm._v('"Image Problem" button')]),
        _vm._v(" at the bottom of the screen and proceed to the next image.")
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("Below are some examples of images and the correct answer."),
        _c("br"),
        _c(
          "span",
          { staticStyle: { "font-size": "16px", "padding-left": "1em" } },
          [_vm._v("(left): Original image, (right): The correct answer.")]
        )
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src:
                "/static/kubota/172137139645586-20181126040043_00_352_53_590_264.jpg"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_2.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_2_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_3.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_3_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_4.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_4_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_5.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_5_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_6.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_6_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_7.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_7_anno.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_8.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/hip_dir_exam_8_anno.png"
            }
          })
        ])
      ]),
      _c("p", { staticClass: "indent-1" }, [
        _c(
          "span",
          { staticStyle: { "font-size": "16px", "padding-left": "1em" } },
          [
            _vm._v(
              "If the base of the tail is not visible, predict its position from the image and click outside the image."
            )
          ]
        )
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_3.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_3_arrow.png"
            }
          })
        ])
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_1.png"
            }
          })
        ]),
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_hip_direction/no_hip_ex_1_arrow.png"
            }
          })
        ])
      ]),
      _c("br"),
      _c("p", { staticClass: "indent-1" }, [
        _vm._v("Below is the example of a "),
        _c("b", [_vm._v('"Can\'t tell"')]),
        _vm._v(" image.")
      ]),
      _c("div", { staticClass: "example" }, [
        _c("figure", [
          _c("img", {
            attrs: {
              src: "/static/sample_images_direction/direction_cant_tell.png"
            }
          })
        ])
      ]),
      _c("br"),
      _c("h3", [_vm._v("Notes:")]),
      _c("ul", [
        _c("li", [
          _vm._v("You will need to send answers "),
          _c("b", [_vm._v("10 times")]),
          _vm._v(" to finish microtask. ")
        ])
      ]),
      _c("div", [
        _c("i", [
          _vm._v(
            "Your answers of this task are used for the research of improving animal farming. Thank you so much for your cooperation."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }