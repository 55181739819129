var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "pa-10": "" } },
    [
      _c(
        "v-card",
        { staticClass: "mx-auto my-5 pa-5", attrs: { "max-width": "1000" } },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("header", { class: _vm.header_color(true) }, [
              _c("b", [_vm._v("Q1.")]),
              _vm._v(" "),
              _c("b", [_vm._v("Click")]),
              _vm._v(" on "),
              _c("u", [_vm._v("the cow's hip")]),
              _vm._v(".")
            ]),
            _c("header", { class: _vm.header_color(false) }, [
              _c("b", [_vm._v("Q2.")]),
              _vm._v(" "),
              _c("b", [_vm._v("Click")]),
              _vm._v(" on "),
              _c("u", [_vm._v("the cow's head")]),
              _vm._v(".")
            ])
          ]),
          _c(
            "v-row",
            {
              staticClass: "mx-auto my-3",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("img", {
                ref: "cow_image",
                attrs: {
                  id: "img",
                  height: _vm.img_height,
                  contain: "",
                  src: _vm.nano.props.img_url0
                }
              }),
              _c("canvas", {
                ref: "canvas",
                attrs: {
                  id: "myCanvas",
                  height: _vm.canvas_height,
                  width: _vm.canvas_width,
                  contain: ""
                },
                on: {
                  mousemove: _vm.mousepos,
                  mouseleave: _vm.out_canvas,
                  click: _vm.get_pos
                }
              })
            ]
          ),
          _c(
            "v-row",
            { staticClass: "mx-auto my-14" },
            [
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.get_pos_hip_X,
                    expression: "get_pos_hip_X"
                  }
                ],
                domProps: { value: _vm.get_pos_hip_X },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.get_pos_hip_X = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.get_pos_hip_Y,
                    expression: "get_pos_hip_Y"
                  }
                ],
                domProps: { value: _vm.get_pos_hip_Y },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.get_pos_hip_Y = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.img_mag,
                    expression: "img_mag"
                  }
                ],
                domProps: { value: _vm.img_mag },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.img_mag = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.get_pos_dir_X,
                    expression: "get_pos_dir_X"
                  }
                ],
                domProps: { value: _vm.get_pos_dir_X },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.get_pos_dir_X = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.get_pos_dir_Y,
                    expression: "get_pos_dir_Y"
                  }
                ],
                domProps: { value: _vm.get_pos_dir_Y },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.get_pos_dir_Y = $event.target.value
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false"
                  },
                  {
                    name: "nano",
                    rawName: "v-nano.required",
                    modifiers: { required: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ang,
                    expression: "ang"
                  }
                ],
                domProps: { value: _vm.ang },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.ang = $event.target.value
                  }
                }
              }),
              _c(
                "v-col",
                { attrs: { cols: "3", align: "left" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { height: "50", disabled: !_vm.get_pos_flag },
                      on: { click: _vm.get_init_info }
                    },
                    [_vm._v("Reset")]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { height: "50", disabled: _vm.get_pos_flag },
                      on: {
                        click: function($event) {
                          return _vm.unclear()
                        }
                      }
                    },
                    [_vm._v("Image Problem")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-3",
                      attrs: { height: "50", disabled: !_vm.get_dir_flag },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("Next")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }