var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "block" }, [
      _vm._v(" Please write something here, if you have something to report."),
      _c("br"),
      _c("br"),
      _c("textarea", {
        directives: [
          { name: "nano", rawName: "v-nano" },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.note,
            expression: "note"
          }
        ],
        domProps: { value: _vm.note },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.note = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit },
          on: {
            click: function($event) {
              _vm.canSubmit ? _vm.submit() : false
            }
          }
        },
        [_vm._v("NEXT")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }