var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: _vm.nano.props.img_url0 } })
      ]),
      _c("div", { staticClass: "arrow" }, [_vm._v("⬌")]),
      _c("div", { staticClass: "img-wrapper" }, [
        _c("img", { attrs: { src: _vm.nano.props.img_url1 } })
      ])
    ]),
    _c("div", { staticClass: "flex" }, [
      _c(
        "button",
        {
          staticClass: "button same",
          on: {
            click: function($event) {
              return _vm.submitChoice("Sitting")
            }
          }
        },
        [_vm._v("Sitting")]
      ),
      _c(
        "button",
        {
          staticClass: "button msame",
          on: {
            click: function($event) {
              return _vm.submitChoice("Maybe Sitting")
            }
          }
        },
        [_vm._v("Maybe Sitting")]
      ),
      _c(
        "button",
        {
          staticClass: "button mdiff",
          on: {
            click: function($event) {
              return _vm.submitChoice("Maybe Standing")
            }
          }
        },
        [_vm._v("Maybe Standing")]
      ),
      _c(
        "button",
        {
          staticClass: "button diff",
          on: {
            click: function($event) {
              return _vm.submitChoice("Standing")
            }
          }
        },
        [_vm._v("Standing")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }