var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "block" }, [
      _vm._v(" 氏名"),
      _c("span", { staticClass: "req" }, [_vm._v("*")]),
      _c("br"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true }
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.name,
            expression: "name"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.name },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.name = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "block" }, [
      _vm._v(" 学年（小林小川研の学生以外は、所属）"),
      _c("span", { staticClass: "req" }, [_vm._v("*")]),
      _c("br"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true }
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.grade,
            expression: "grade"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.grade },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.grade = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "block" }, [
      _vm._v(" メールアドレス"),
      _c("span", { staticClass: "req" }, [_vm._v("*")]),
      _c("br"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true }
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.email },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.email = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "block" }, [
      _vm._v(" 電話番号"),
      _c("span", { staticClass: "req" }, [_vm._v("*")]),
      _c("br"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true }
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.phone,
            expression: "phone"
          }
        ],
        attrs: { type: "text" },
        domProps: { value: _vm.phone },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.phone = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit },
          on: {
            click: function($event) {
              _vm.canSubmit ? _vm.submit() : false
            }
          }
        },
        [_vm._v("NEXT")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }